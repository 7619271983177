import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "@fortawesome/fontawesome-free/css/all.min.css"

import configs from "../../site-config"

const TermsOfServicePage = ({ data }) => (
  <Layout>
    <SEO title="Terms of Service" keywords={configs.app_keywords} />

    <div className="headerBackground">
      <div className="privacy-container">
        <header>
            <div className="logo">
              <div className="appIconShadow">
                <svg width="0" height="0">
                  <defs>
                    <clipPath id="shape">
                      <path
                        id="shape"
                        d="M6181.23,233.709v-1.792c0-.5-0.02-1-0.02-1.523a24.257,24.257,0,0,0-.28-3.3,11.207,11.207,0,0,0-1.04-3.132,10.683,10.683,0,0,0-1.95-2.679,10.384,10.384,0,0,0-2.68-1.943,10.806,10.806,0,0,0-3.13-1.038,19.588,19.588,0,0,0-3.3-.285c-0.5-.017-1-0.017-1.52-0.017h-22.39c-0.51,0-1.01.017-1.53,0.017a24.041,24.041,0,0,0-3.3.285,11.009,11.009,0,0,0-3.13,1.038,10.491,10.491,0,0,0-4.62,4.622,10.893,10.893,0,0,0-1.04,3.132,19.2,19.2,0,0,0-.28,3.3c-0.02.5-.02,1-0.02,1.523v22.392c0,0.5.02,1,.02,1.524a24.257,24.257,0,0,0,.28,3.3,10.9,10.9,0,0,0,1.04,3.132,10.491,10.491,0,0,0,4.62,4.622,11.04,11.04,0,0,0,3.13,1.038,19.891,19.891,0,0,0,3.3.285c0.51,0.017,1.01.017,1.53,0.017h22.39c0.5,0,1-.017,1.52-0.017a24.221,24.221,0,0,0,3.3-.285,10.836,10.836,0,0,0,3.13-1.038,10.408,10.408,0,0,0,2.68-1.943,10.683,10.683,0,0,0,1.95-2.679,11.217,11.217,0,0,0,1.04-3.132,20.257,20.257,0,0,0,.28-3.3c0.02-.5.02-1,0.02-1.524v-20.6h0Z"
                        transform="translate(-6131 -218)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                <Img
                  fluid={data.headerIcon.childImageSharp.fluid}
                  className="headerIcon"
                />
              </div>
              <p className="headerName">{configs.app_name}</p>
            </div>
            
              <nav>
                <ul>
                {configs.presskit_download_link && (
                  <li>
                    <a href={configs.presskit_download_link}>Press Kit</a>
                  </li>
                  )}
                  <li>
                    <a href="/">Home</a>
                </li>
                </ul>
              </nav>
            
          </header>

          <div className="privacy-content">
          <h1>"Guiding Ring" Service Agreement and Copyright Notice</h1>
          <br></br> 
          <p>
          This document is part of the Guiding Ring terms and conditions. It applies to users who have downloaded the Guiding Ring app and/or have purchased a Guiding Ring Pro Access membership. If you do not agree to these terms, you must not use the Guiding Ring app. If you are using the Guiding Ring app for a business or organization, you are agreeing to these terms on behalf of the business/organization to these terms, and “you” and “your” will refer to that business/organization.
          </p>

          <p>
          This document works in conjunction with the <a href="/privacy">Privacy Policy</a> and <a href="/terms">Terms of Use</a>.
          </p>

          <h2>
          Content Policy &#38; Restricted Use
          </h2>
          <br></br>

          <p>
          Before reposting, posting, saving, sharing and using content, you must make sure that you have the permission to use that content from all copyright owners of the media.
          </p>
          <br></br>
          <p>
          By using this app, you agree to our Terms and that it is your legal responsibility to make sure that you have permission to make use of all media that you are reposting, posting, saving, sharing or using. You must directly contact and receive permission from all copyright owners of media before using that content.  We will not be liable for any claims regarding copyright as you agree that it your responsibility to get permission to post all media that you post, save, share, repost or use and that  you will not make use of content without permission from copyright owners. 
          </p>
          <br></br>

          <p>
          Attribution may be given with a watermark or caption tag and must be discussed with copyright owners beforehand. If any users are found to violate these terms, they may be banned from using the service.  You are responsible for all of your activity in connection with this app. Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your right to access or use the app.
          </p>
          <br></br>

          <p>
          You warrant, represent and agree that you will not contribute any Content or otherwise use the Services in a manner that (i) infringes or violates the intellectual property rights or proprietary rights, rights of publicity or privacy, or other rights of any third party; (ii) violates any law, statute, ordinance or regulation; (iii) is harmful, fraudulent, deceptive, threatening, abusive, harassing, degrading, intimidating, tortious, defamatory, vulgar, obscene, libelous, or otherwise objectionable; (iv) impersonates any person or entity, including without limitation any employee or representative of Company; (v) restricts or inhibits any other user from using and enjoying the Services or Content; (vi) relate to products that are sexual or pornographic in nature, alcoholic products, tobacco products or other products that are unlawful in any manner; or (vii) contains a virus, trojan horse, worm, time bomb, or other harmful computer code, file, or program. We reserves the right to remove any Content from the Services at any time, for any reason (including, but not limited to, upon receipt of claims or allegations from third parties or authorities relating to such Content or if Company is concerned that you may have breached the immediately preceding sentence), or for no reason at all. You, not Company, remain solely responsible for all Content that you upload, post, email, transmit, or otherwise disseminate using, or in connection with, the Services, and you warrant that you possess all rights necessary to provide such content to Company and to grant Company the rights to use such information in connection with the Services and as otherwise provided herein.
          </p>

          <p>
          Further you will only share media to platforms or services as per the terms agreed upon by you and any copyright owners. If any residual media is obtained during the posting or sharing process, it must be discarded after posting or sharing and must not be used for any other purpose.
          </p>

          <p>
          You are responsible for all of your activity in connection with the Services. Any fraudulent, abusive, or otherwise illegal activity may be grounds for termination of your right to access or use the Services. You may not post or transmit, or cause to be posted or transmitted, any communication or solicitation designed or intended to obtain password, account, or private information from any other user of the Services. Use of the Services to violate the security of any computer network, crack passwords or security encryption codes, transfer or store illegal material (including material that may be considered threatening or obscene), or engage in any kind of illegal activity is expressly prohibited. You will not run Maillist, Listserv, any form of auto-responder, or "spam" on the Services, or any processes that run or are activated while you are not logged on to the Website, or that otherwise interfere with the proper working of or place an unreasonable load on the Services' infrastructure. Further, the use of manual or automated software, devices, or other processes to "crawl," "scrape," or "spider" any page of the Website is strictly prohibited. You will not decompile, reverse engineer, or otherwise attempt to obtain the source code of the Services. You will be responsible for withholding, filing, and reporting all taxes, duties and other governmental assessments associated with your activity in connection with the Services.
          </p>

          <br></br>
          <p>
          YOU UNDERSTAND AND ACKNOWLEDGE THAT YOU ARE RESPONSIBLE FOR ANY CONTENT YOU SUBMIT, CONTRIBUTE, SHARE OR OTHERWISE CAUSE TO BE TRANSMITTED THROUGH THE SERVICES, AND YOU, NOT THE COMPANY, HAVE FULL RESPONSIBILITY FOR SUCH CONTENT, INCLUDING ITS LEGALITY, RELIABILITY, ACCURACY AND APPROPRIATENESS.
          </p>

          <p>
          WE ARE NOT RESPONSIBLE, OR LIABLE TO YOU OR ANY THIRD PARTY, FOR THE CONTENT OR ACCURACY OF ANY USER CONTRIBUTIONS PROVIDED BY YOU OR ANY OTHER USER OF THE SERVICES, INCLUDING THE GUIDING RING APP.
          </p>
          <br></br>
          <h2>
          Limitations Of Liability
          </h2>
          <br></br>

          <p>
          You expressly understand and agree that we and our affiliates, officers, employees, agents, partners, and licensors shall not be liable to you for any direct, indirect, incidental, special, consequential, or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if we have been advised of the possibility of such damages), resulting from: (i) the use or the inability to use our Services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services obtained or messages received or transactions entered into through, from or as a result of our Services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on our Services; or (v) any other matter relating to us or our Services. Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations in this paragraph may not apply to you.
          </p>

          <p>
          We are not liable for anything that happens to you that somehow may be connected to your use of our Services. If you use our Services in a way that causes us to be included in litigation, you agree to pay all legal fees and costs we incur.
          </p>

          <p>
          You further agree and understand that we have no liability for the loss of any information you may chose to store in your account used to register for our Services or that you transmit or cause to be transmitted through use of the Guiding Ring app, in the event your computer or mobile device is sold, lost or stolen.
          </p>

          <p>
          You agree to indemnify and hold us and our subsidiaries, affiliates, officers, agents, employees, partners, and licensors harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your use of our Services.
          </p>

          <h2>
          Service content and third party links
          </h2>
          <br></br>

          <p>
          We only provide service content, including but not limited to service content, for educational, entertainment and/or promotional purposes only. You may not rely on any information and opinions expressed by us through any service for any other purpose. In all cases, you are responsible for assessing the accuracy, timeliness, completeness or usefulness of any Service Content. Under no circumstances will we be liable for any loss or damage caused by your reliance on any of the Services. 
          </p>

          <p>
          In many cases, the content of the service will include content posted by third parties or opinions and judgments on behalf of third parties. We do not endorse, guarantee and assume no responsibility for the accuracy, timeliness, completeness or statement of any opinions, suggestions or statements provided by our authorized employees or spokespersons in their official capacity.
          </p>

          <p>
          Services can link or include links to other websites maintained by third parties. We do not operate or control or must endorse the content on these third party websites in any way. You are solely responsible for the use of third party links. We are not responsible or liable to any content posted on third party websites for any loss or damage of any kind whatsoever arising out of dealings with you and any third party or its website.
          </p>

          <h2>
          Indemnity
          </h2>

          <br></br>
          <p>
          You will indemnify and hold harmless the developers of Guiding Ring and its officers, directors, employees and agents, from and against any claims, disputes, demands, liabilities, damages, losses, and costs and expenses, including, without limitation, reasonable legal and accounting fees, arising out of or in any way connected with (i) your access to or use of Guiding Ring or our licensors’ intellectual property; (ii) your content; or (iii) your violation of these Terms.
          </p>

          <h2>
          Warranty Disclaimers
          </h2>

          <br></br>
          <p>
          The GUIDING RING APP OR OUR LICENSORS’ INTELLECTUAL PROPERTY ARE PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE EXPLICITLY DISCLAIM ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT AND NON-INFRINGEMENT AND ANY WARRANTIES ARISING OUT OF COURSE OF DEALING OR USAGE OF TRADE. WE MAKE NO WARRANTY THAT GUIDING RING WILL MEET YOUR REQUIREMENTS OR BE AVAILABLE ON AN UNINTERRUPTED, SECURE, OR ERROR-FREE BASIS. WE MAKE NO WARRANTY REGARDING THE QUALITY, ACCURACY, TIMELINESS, TRUTHFULNESS, COMPLETENESS OR RELIABILITY OF ANY OF OUR INTELLECTUAL PROPERTY.
          </p>

          <h2>
          Who Is Responsible if Something Happens.
          </h2>

          <br></br>
          <p>
          Our Service is provided "as is," and we can't guarantee it will be safe and secure or will work perfectly all the time. TO THE EXTENT PERMITTED BY LAW, WE ALSO DISCLAIM ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT.
          We also don’t control what people and others do or say, and we aren’t responsible for their (or your) actions or conduct (whether online or offline) or content (including unlawful or objectionable content). We also aren’t responsible for services and features offered by other people or companies, even if you access them through our Service.
          Our responsibility for anything that happens on the Service (also called "liability") is limited as much as the law will allow. If there is an issue with our Service, we can't know what all the possible impacts might be. You agree that we won't be responsible ("liable") for any lost profits, revenues, information, or data, or consequential, special, indirect, exemplary, punitive, or incidental damages arising out of or related to these Terms, even if we know they are possible. This includes when we delete your content, information, or account.
          </p>

          <h2>
          Changes to This Policy
          </h2>

          <br></br>
          <p>
          We may update our terms of services or privacy policy to reflect changes in our terms and data privacy practices. If we do this, we post the revised terms here and revised privacy policy on our Guiding Ring privacy policy page with an updated date. We encourage you to check these pages regularly for the latest information.
          </p>

          <h2>
          Misc
          </h2>

          <br></br>
          <p>
          Unless otherwise expressly stated, if any part of these Terms of Use is illegal or unenforceable for any reason, we agree that only that portion of the Terms of Use is compromised and that the remaining terms of the Terms of Use are not affected.
          </p>

          <p>
          You may not assign these Terms of Use or assign any rights or all or part of the obligations under this Agreement without our prior written consent. Any such alleged assignment or assignment is void and invalid without prior written consent. We may transfer these Terms of Use or any rights under this Agreement without your consent without prior notice.
          </p>

          <h2>
          Contact us
          </h2>

          <br></br>
          <p>
          If you have any questions regarding privacy while using the Application, or have questions about our practices, please contact us via email at contact@rpgguideme.app. These terms may be updated from time to time, so please check this page for any changes. 
          </p>
          <p>
            These terms of Service are effective as of 2022-03-01
          </p>
          </div>
  

          <footer>
            <p className="footerText">
              Made with ❤️ by{" "}
              {configs.your_link ? (
                <a href={configs.your_link}>{configs.your_name}</a>
              ) : (
                `${configs.your_name}`
              )}
              {configs.your_city && ` in ${configs.your_city}`}
            </p>
            <div className="footerIcons">
              {configs.facebook_username && (
                <a
                  href={`https://facebook.com/${configs.facebook_username}`}
                  aria-label="Facebook"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-facebook fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.linkedin_username && (
                <a
                  href={`https://www.linkedin.com/in/${configs.linkedin_username}`}
                  aria-label="LinkedIn"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-linkedin fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.twitter_username && (
                <a
                  href={`https://twitter.com/${configs.twitter_username}`}
                  aria-label="Twitter"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-twitter fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.github_username && (
                <a
                  href={`https://github.com/${configs.github_username}`}
                  aria-label="GitHub"
                >
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fab fa-github fa-stack-1x" />
                  </span>
                </a>
              )}

              {configs.email_address && (
                <a href={`mailto:${configs.email_address}`} aria-label="Email">
                  <span className="fa-stack fa-1x">
                    <i className="socialIconBack fas fa-circle fa-stack-2x" />
                    <i className="socialIconTop fas fa-envelope fa-stack-1x" />
                  </span>
                </a>
              )}
            </div>
          </footer>
      </div>
    </div>

  </Layout>
)

export default TermsOfServicePage

export const query = graphql`
  query {
    headerIcon: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 50) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    appStore: file(relativePath: { eq: "appstore.png" }) {
      childImageSharp {
        fixed(width: 220) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    playStore: file(relativePath: { eq: "playstore.png" }) {
      childImageSharp {
        fixed(height: 75) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iphoneScreen: file(relativePath: { glob: "screenshot/*.{png,jpg}" }) {
      childImageSharp {
        fluid(maxWidth: 350) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    videoScreen: file(
      extension: { ne: "txt" }
      relativePath: { glob: "videos/*" }
    ) {
      publicURL
      extension
    }
    appIconLarge: file(relativePath: { eq: "icon.png" }) {
      childImageSharp {
        fluid(maxWidth: 120) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headerimage.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 714) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewBlack: file(relativePath: { eq: "black.png" }) {
      childImageSharp {
        fluid(maxWidth: 400, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
    iphonePreviewBlue: file(relativePath: { eq: "blue.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewCoral: file(relativePath: { eq: "coral.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewWhite: file(relativePath: { eq: "white.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    iphonePreviewYellow: file(relativePath: { eq: "yellow.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`